.tcap-connect {
  margin: 1em;

  &-header {
    padding: 0.5rem 1rem;
  }

  &__form-wrapper {
    margin-top: 2em;
  }

  p {
    margin: 1em 0;
  }

  input {
    margin-right: 1em;
  }

  h5 {
    margin: 1em 0;
  }
}



.is-loading {
  position: relative;
  height: 30px;
}

.is-loading::after {
  content: " ";
  position: absolute;
  width: 16px;
  height: 16px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 4px solid transparent;
  border-top-color: #ffffff;
  border-radius: 50%;
  animation: button-loading-spinner 1s ease infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}
