@import '../Theme/theme-variables';

.Toastify__toast--info {
  background-color: $info;
}
.Toastify__toast--success {
  background-color: $success;
}
.Toastify__toast--warning {
  background-color: $warning;
}
.Toastify__toast--error {
  background-color: $danger;
}
